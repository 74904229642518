/* Core Variables and Mixins */

@import "../../ga/styles/partials/variables";
@import "../../ga/styles/partials/mixins";

/*@import "../../ga/styles/partials/foundation";
@import "../../ga/styles/partials/print"; */

/* Core CSS
 *
 * NOTE - _type.css: Contains _typescale.css: written and compiled from scss folder
 * DO NOT EDIT _grid.css - Written and compiled from scss folder
 */

@import "../../ga/styles/partials/fontassets";
@import "../../ga/styles/partials/fontsloaded";
@import "../../ga/styles/utilities/colors";
@import "../../ga/styles/partials/accessibility";
@import "../../ga/styles/partials/grid";

/*@import "../../ga/styles/partials/type";
@import "../../ga/styles/partials/lists";*/
@import "../../ga/styles/partials/images";
/*@import "../../ga/styles/partials/grid";
@import "../../ga/styles/partials/tables"; */

@import "../../ga/styles/partials/footers";

/* @import "../../ga/styles/partials/forms"; */

@import "../../ga/styles/partials/buttons";
@import "../../ga/styles/partials/jumbotrons";

/* Components */

@import "../../ga/styles/partials/slider";
@import "../../ga/styles/partials/nav";
@import "../../ga/styles/partials/navbar";
@import "../../ga/styles/utilities/spacers";

/* Business Partials */

/*@import "partials/footer";*/
@import "partials/nav";
@import "partials/mall";
@import "../../ga/styles/partials/headerfooter";
@import "../../ga/styles/partials/sidenav";

html,
body {
    /*overflow-x: hidden;*/

    @media (--lg-viewport-up) {
        .hero-text-background {
            width: 33%;
        }
    }

    @media (--xl-viewport-up) {

        #heroCarousel .carousel-indicators {
            margin-right: 5%;
        }

        .hero-text-background {
            width: 25%;
        }
    }
}

body {
    background: var(--white);
    color: var(--black);
    margin-top: calc(var(--navbar-global-height) + var(--navbar-height) + var(--navbar-sub-height));
    @media screen and (max-width: 992px) {
        margin-top: calc(var(--navbar-mobile-height) + var(--navbar-global-height));
    }
}

body:has(#SimonCentralHeader) {
    margin-top: calc(var(--navbar-global-height) + var(--navbar-height) + var(--navbar-height) + var(--navbar-sub-height));
}

#SimonCentralHeader ~ #fullHeader {
    top: var(--navbar-height);
}

#menuBar {
    height: var(--navbar-sub-height);
}

.submenu {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-block-color: var(--gray);
}

.sidenav {
    & dd:first-child
    {
        padding-top: 15px;
    }
    & dl.link-group 
    {
        margin-top: 0;
        margin-bottom: 0;
    }
    & li a
    {
        padding: 15px 15px 15px 30px;
    }
    & li dd a{
        padding-top: 0px;
        padding-left: 10px;
    }
}

#externalContent {
    min-height: 100vh;
}

a.spg-button {
    font-size: 0.8rem;
    border-radius: 0;
    text-transform: uppercase;
}

a.btn-black-white {
  color: var(--white);
  background-color: var(--black);
  border-color: var(--black);
}

a.btn-white-black {
  color: var(--black);
  background-color: var(--white);
  border: solid;
  border-width: 2px;

  &:hover,
  &:focus {
    background-color: var(--gray);
    color: var(--white);
  }
}

.overlay-container {
  position: relative;
  text-align: left;
  color: red;
}

.center-left {
  position: absolute;
  top: 50%;
  background-color: black;
}

.simon-section-gray {
    background-color: var(--gray-lighter);
}

.hero-text-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 33%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.5rem;
}

.hero-text-background a.d-sm-block {
    color: var(--white);

    &:hover, 
    &:focus {
       color:var(--gray-light);
    }
}

#centerDetails a {
    color: var(--black);

    &:hover, 
    &:focus {
       color:var(--gray);
    }
}

.explore-slider {
  & img {
    width: 100%;
  }

  & .header-md a {
    text-decoration: none;
  }
}

@media screen and (max-width: 767px) {
  .hero-text-background {
    background-color: rgba(0, 0, 0, 0.5);
    top: auto;
    bottom: 0;
    width: 100%;
    height: 45%;
  }

  .hero-text-background img {
    width: 100%;
  }

  .fonts-loaded .spg-ad-spot h2 span {
    color: #000;
  }

  .fonts-loaded .spg-ad-spot br {
    display: none;
  }
}

.stats-section {
  background-color: #000;
  background-image: url(https://assets.simon.com/content/businessimages/bannerimages/large-black-simon-pattern.png);
  background-size: cover;
}

.propStat h3 {
  color: var(--brand-secondary);
}

.propStat p {
  color: #fff;
}

.search-section {
  background-color: var(--brand-secondary);
  background-image: url(https://assets.simon.com/content/businessimages/bannerimages/large-teal-simon-pattern.png);
  background-repeat: repeat;
}

.by-the-numbers,
.contact-listings {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

figure.busProperty {
  margin: 0;
}

.accordion-button,
.form-check-label {
  color: var(--gray);
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: white;
  color: black;
}

:not(.form-switch) > .form-check-input[type="checkbox"],
input[type="search"] {
  border-radius: 0;
}

.form-check-input:checked {
  background-color: var(--brand-primary);
  border-color: var(--brand-primary);
}

.form-check-input:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='8' d='M0 0 L0 20 L20 20 L20 0 Z'/%3e%3c/svg%3e");
}

input[type="checkbox"]:checked + label,
input[type="radio"]:checked + label {
  color: var(--brand-primary);
}

@media only screen and (max-width: 767px) {
  .by-the-numbers,
  .contact-listings {
    grid-template-columns: 1fr;
  }
}

#heroCarousel picture img {
  object-fit: cover;
  object-position: center;
  min-width: 100%;
}

.text-copyright {
  font-size: 0.75rem;
}

.text-copyright a:hover {
  color: var(--black);
}

.pastel-aqua {
  background-color: #00aeef;
}

.pastel-teal {
  background-color: #66c5b3;
}

.pastel-salmon {
  background-color: #f16b81;
}

.lemon {
  background-color: #d3df4e;
}

.simon-plum {
  background-color: var(--brand-primary);
}

.bg-gray-lighter {
  background-color: var(--gray-lighter);
}

.bg-gray-darker {
  background-color: var(--gray-darker);
}

footer a, footer a:hover {
  color: var(--black);
}

a.link-teal {
  color: var(--brand-secondary);
}

.mall-map-card {
  border-radius: 0;
  border: none;
}

.mall-map-card .card-img-top {
  border-radius: 0;
  background-color: var(--black);
}

.info-icon {
  fill: var(--black);
  height: var(--footer-social-icon-size);
  width: var(--footer-social-icon-size);
}

.modal-content.modal-spg {
  border-radius: 0;
}

.modal-content.modal-spg a {
  text-decoration: none;
  color: var(--black);
}

.modal-content.modal-spg .list-group-item a {
  color: var(--gray);
  & svg {
    fill: var(--gray);
  }

  &:hover,
  &:focus {
    color: var(--black);

    & svg {
      fill: var(--black);
    }
  }
}

.modal-dialog {
  @media (--md-viewport-up) {
    max-width: 600px;
  }

  @media (--lg-viewport-up) {
    max-width: 800px;
  }

  @media (--xl-viewport-up) {
    max-width: 900px;
  }
}

svg.leasing-info,
img.leasing-info,
.leasing-info {
  fill: #fff;
  width: 100%;
  cursor: pointer
}

button.card,
a.card {
    cursor: pointer;

    & .card-body {
        transform: scale(1);
        transition: transform 0.2s;
    }

    &:hover .card-body, &:focus .card-body {
        transform: scale(0.95);
    }
}

.no-underline {
  text-decoration: none;
}

.error-404 {
  background-color: #e7ebec;

  &-title {
    line-height: 0.75;
  }

  &-text {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 50%;

    @media screen and (max-width: 767px) {
      position: relative;
      width: 100%;
      transform: none;
      top: 20px;
      padding-bottom: 20px;
    }
  }
}

.field-validation-error {
  color: var(--brand-primary);
}

.form-floating > input.form-control {
  width: 100%;
}

.form-control,
.form-floating > .form-select,
.form-control > textarea {
  border-radius: 0;
  border-color: #000;
}

.form-control:focus,
.accordion-button:focus,
.form-floating > .form-select:focus,
.form-check-input[type="checkbox"]:focus {
  border-color: #000;
  box-shadow: none;
}

.form-check-input[type="radio"]:focus,
.btn:focus {
  box-shadow: none;
}

textarea {
  border-color: #000;
  outline: none;
}

#searchForm {
  position: sticky;
  top: calc(74px);
  padding-top: 0.86806rem;
  padding-bottom: 1.5rem;
  margin-top: -0.86806rem;
  z-index: 2;
  background-color: var(--gray-lighter);

  @media (--sm-viewport-down) {
    margin-bottom: 0;
  }
}

#collapseFilter > div {
  @media (--md-viewport-up) {
    position: sticky;
    top: calc(87px + 0.86806);
    max-height: calc(100vh - 74px - 0.86806rem * 2);
    overflow-y: auto;
  }
}

#mobileSearchFilterButtons {
  position: sticky;
  top: calc(74px + 1.5rem + 0.86806rem + 52px);
  background: var(--gray-lighter);
  z-index: 1;
  padding-bottom: 1.5rem;
  margin-bottom: -1.5rem;
}

#collapseFilter {
    /* Firefox */
    & * {
    scrollbar-width: auto;
    scrollbar-color: var(--gray-light) var(--white);
  }

  /* Chrome, Edge, and Safari */
  & *::-webkit-scrollbar {
    width: 10px;
  }

  & *::-webkit-scrollbar-track {
    background: var(--white);
  }

  & *::-webkit-scrollbar-thumb {
    background-color: var(--gray-light);
    border-radius: 0px;
    border: 2px solid var(--white);
  }

  .form-check-input:checked {
      background-color: var(--brand-secondary);
      border-color: var(--brand-secondary);
  }
  input[type=checkbox]:checked+label, input[type=radio]:checked+label{
      color: var(--brand-secondary);
  }
  button.no-button-style:focus, button.no-button-style:hover {
      color: var(--brand-secondary);
  }
.accordion-button:not(.collapsed):after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23008f94'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}
}

#gridLeasingProperties {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1.6rem;
  grid-auto-rows: minmax(250px, auto);
}

@media (max-width: 1200px) {
  #gridLeasingProperties {
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: minmax(200px, auto);
  }
}

@media (max-width: 992px) {
  #gridLeasingProperties {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(150px, auto);
  }
}

@media (max-width: 767px) {
  #gridLeasingProperties {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(150px, auto);
  }
}

#map {
  width: 100%;
  min-height: 400px;

  @media (min-width: 767px) {
    min-height: 600px;
  }
}

#mapOverlay {
  position: relative;

  @media (min-width: 767px) {
    min-height: 600px;
  }
}

figure {
  position: relative;
  background-color: #000;
}

figcaption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  width: 100%;
  padding: 1.5rem;
}
figcaption sup {
    font-size: .8rem;
}
figcaption h6 {
    font-size: 1.25rem;
}

figure.busProperty img {
  opacity: 0.5;
}

@media (--md-viewport-down)  {
    figcaption {
        padding: .5rem;
    }
    figcaption h6 {
        font-size: 1rem;
    }
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%239f005b'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    transform: rotate(-180deg);
}

@media (min-width: 768px) {
  .collapse.dont-collapse-sm {
    display: block;
    visibility: visible;
  }
  #heroCarousel img {      
    max-height: 600px;
  }
}

body.offcanvas-active {
  overflow: hidden;
}

.offcanvas-header {
  display: none;
}

.screen-darken {
  height: 100%;
  width: 0%;
  z-index: 30;
  position: fixed;
  top: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(255, 255, 255, 0.8);
  transition: opacity 0.2s linear, visibility 0.2s, width 2s ease-in;
}

.screen-darken.active {
  z-index: 10;
  transition: opacity 0.3s ease, width 0s;
  opacity: 1;
  width: 100%;
  visibility: visible;
}

.key-tenant-slider img {
  display: block;
  padding: 5%;
  width: 100%;
  transition: padding 0.15s ease-out;

  &:hover {
    padding: 8%;
  }
}

.key-tenant-slider.slick-slider,
.leasing-slider.slick-slider {
  overflow: visible;
  
  & .slick-list {
    overflow-x: hidden;

    & .slick-track {
      display: flex;
      margin: 0 auto;

      & .slick-slide {
        height: inherit;
      }
    }
  }

  & .logo-slider-gray {
    margin: 0.3rem;
  }
}

.key-tenant-slider .slick-track {
  margin: 0 auto;
}

.slick-slider .slick-arrow {
  background-color: transparent;
}

.slick-slider:not(.leasing-slider) .slick-arrow {
  &.slick-prev {
    margin-left: -1.25rem;
  }
  &.slick-next {
    margin-right: -1.25rem;
  }
}

.explore-slider,
.explore-slider .row > .HtmlContentClass {
  padding: 0;
}

.jqryLeasingInformation .card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.leasing-slider .card {
  border: none;
  border-radius: 0;
  text-decoration: none;
}

#megaMenu {
    position: absolute;
    width: 100%;
    z-index: 5;
}

.mega-dropdown a {
    text-decoration: none;
    color: #757575;
}
.mega-dropdown {
    padding-top: 1.75rem;
    /*padding-bottom: 1.75rem;*/
}
.mega-dropdown a:hover {
    color: black;
    text-decoration: underline;
}

.mega-dropdown li,
.mega-dropdown h5 {
    padding-bottom: 0.5rem;
}

.main-logo {
    max-width: 200px;
}

a.inline-link {
    color: inherit;
    text-decoration: underline;
}

.doc-multi-icon {
    width: 3rem;
    height: 3rem;
    margin-top: -0.85rem;
    margin-bottom: -1rem;
}

.jumbotron .jumbotron-bg img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
}

.btn-brand-secondary {
  background-color: var(--brand-secondary);
  color: var(--white);

  &:hover,
  &:focus {
    background-color: var(--brand-gray);
    color: var(--white);
  }
}

button.no-button-style {
    padding: 0;
    border: 0;
    background: transparent;
    
    color: var(--gray);
    
    &:hover,
    &:focus {
        color: var(--brand-primary);
    }
}

.btn.btn-outline-primary:hover,
.btn.btn-outline-primary:active,
.btn.btn-outline-primary:focus {
    background-color: transparent;
    box-shadow: none;
}

@media all and (max-width: 1200px) {
    .navbar-nav .nav-item + .nav-item {
        margin-left: 1.2rem;
    }
}

/* ============ mobile view ============  */

@media all and (max-width: 767px) {
  .offcanvas-header {
    display: block;
  }

  .mobile-offcanvas {
    visibility: hidden;
    transform: translateX(-100%);
    border-radius: 0;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1200;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
  }

  .mobile-offcanvas.show {
    visibility: visible;
    transform: translateX(0);
  }

  .mobile-offcanvas .container,
  .mobile-offcanvas .container-fluid {
    display: block;
  }
}

/* ============ mobile view .end// ============ */

/*
 * Buttons
 *
 * Base styles
 */

.btn {
  border: var(--input-btn-border-width) solid transparent;
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  letter-spacing: 0.05em;
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  @include button-size(
    $btn-padding-y,
    $btn-padding-x,
    $btn-font-size,
    normal,
    $btn-border-radius
  );
  @include transition(all, 0.2s, ease-in-out);

  &.focus {
    text-decoration: none;
  }

  &:active,
  &.active {
    background-image: none;
    outline: 0;
  }

  &.disabled,
  &:disabled {
    cursor: var(--cursor-disabled);
    opacity: 0.65;
  }

  & svg {
    height: 1rem;
    margin-right: 5px;
    position: relative;
    top: 3px;
    width: 1rem;

    @include transition(fill, 0.2s, ease-in-out);
  }
}

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}

/* Alternate buttons */

.btn-short {
    padding-top: 5px;
    padding-bottom: 5px;
}

.btn-primary {

  /* background-color:#000;border-color:transparent;color:#fff */

  /* @include button-variant(--btn-primary-color), var(--btn-primary-bg), var(--btn-primary-border), var(--btn-primary-color-active), var(--btn-primary-bg-active), var(--btn-primary-border), var(--btn-primary-color-active-focus)); */

  @include button-variant(
    $btn-primary-color,
    $btn-primary-bg,
    $btn-primary-border,
    $btn-primary-color-active,
    $btn-primary-bg-active,
    $btn-primary-border,
    $btn-primary-color-active-focus
  );

  svg {
    fill: var(--btn-primary-color);
  }
}

.btn-secondary {

  @include button-variant(
    $btn-secondary-color,
    $btn-secondary-bg,
    $btn-secondary-border,
    $btn-secondary-color-active,
    $btn-secondary-bg-active,
    $btn-secondary-border,
    $btn-secondary-color-active-focus
  );

  svg {
    fill: var(--btn-secondary-color);
  }
}


.btn-brand-primary {

  /* background-color:#000;border-color:transparent;color:#fff */

  /* @include button-variant(--btn-primary-color), var(--btn-primary-bg), var(--btn-primary-border), var(--btn-primary-color-active), var(--btn-primary-bg-active), var(--btn-primary-border), var(--btn-primary-color-active-focus)); */

  @include button-variant(
    #fff,
    #9f005b,
    #9f005b,
    #fff,
    #b4b2b0,
    #b4b2b0,
    #fff
  );

  svg {
    fill: var(--btn-primary-color);
  }
}


.btn-search-black {
    @include button-variant(
    $btn-primary-color,
    #000000,
    $btn-primary-border,
    $btn-primary-color-active,
    $btn-primary-bg-active,
    $btn-primary-border,
    $btn-primary-color-active-focus
  );

  svg {
    fill: var(--btn-primary-color);
  }
}

.btn-search-blue {
    @include button-variant( $btn-primary-color, #1A3563, $btn-primary-border, $btn-primary-color-active, $btn-primary-bg-active, $btn-primary-border, $btn-primary-color-active-focus );
    svg

{
    fill: var(--btn-primary-color);
}

}

/* Buttons with borders */

.btn-outline-primary {

  @include button-outline-variant(
    $btn-primary-outline-color,
    $btn-primary-outline-color-active
  );
}

.btn-outline-secondary {

  @include button-outline-variant(
    $btn-secondary-outline-color,
    $btn-secondary-outline-color-active
  );
}

/* Block button - fit full width of parent, and display block */

.btn-block {
  display: block;
  width: 100%;

  & + .btn-block {
    margin-top: var(--btn-block-spacing-y);
  }
}

/* Mobile Only Block Button - fit full width of parent only on sm viewport and lower - used for when design goes full width only on mobile */

.btn-mobile-block {
  &:not(:hidden) + .btn-mobile-block {
    margin-top: var(--btn-block-spacing-y);
  }

  @media (--sm-viewport-down) {
    display: block;
    width: 100%;
  }
}

/* Specificity overrides */

input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}

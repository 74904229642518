.spg-hero {
    position: relative;
    color: black;
    text-decoration: none;
}

    .spg-hero .hero-full-size-link,
    .spg-video-hero .hero-full-size-link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .spg-hero h1, .spg-hero h2 {
        line-height: .8;
    }

    .spg-hero div p a:nth-of-type(2) {
        padding-top: .5rem;
    }

    .hero-text {
        padding: 2.7rem;
        z-index: 2;
    }

    .hero-text p a,
    .spg-ad-spot-link {
        font-family: 'SimonCircularPro-Bold', sans-serif;
        text-transform: uppercase;
    }

    .hero-text h2 a {
        text-decoration: none;
    }

.spg-ad-spot {
    display: block;
}

.spg-ad-spot .hero-text h2,
.explore-slider h5 {
    line-height: 1.25;
    letter-spacing: -0.01em;
}

.spg-ad-spot-link {
    display: inline-block;
    text-transform: uppercase;
    padding-bottom: 0.2rem;
    text-decoration: underline;
    letter-spacing: -0.01em;
}

.hero-top-left {
    position: absolute;
    top: 0;
    left: 0;
}

.hero-top-center {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
}

.hero-top-right {
    position: absolute;
    top: 0;
    right: 0;
}

.hero-middle-left {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0%, -50%);
}

.hero-middle-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.hero-middle-right {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0%, -50%);
}

.hero-bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
}

.hero-bottom-center {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
}

.hero-bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
}

.mall-copy {
    margin-top: 3rem;
    margin-bottom: 3rem;
    text-align: center;
}

    .mall-copy h1 {
        font-size: 1.75rem;
    }

.services-slider {
    margin-bottom: 3rem;
}

.services-header {
    margin-bottom: 3rem;
    margin-top: 3rem;
}

    .services-header h2 {
        margin: 0;
    }

.explore-header {
    margin-bottom: 3rem;
}

    .explore-header h2 {
        margin: 0;
    }

.hero-image-wrapper {
    width: 100%;
}

.spg-hero p a:not(.btn), .spg-video-hero p a:not(.btn) {
    display: block;
}

.spg-video-hero.main-hero .volume {
    display: none;
}

.video-inner-container .play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    & svg

{
    display: block;
}

}

.description-headline :last-child,
.main-hero .hero-text > :last-child,
.spg-ad-spot .hero-text > :last-child,
.spg-video-hero .hero-text > :nth-last-child(2) {
    margin-bottom: 0;
}

.spg-video-hero .hero-text > h2,
.spg-video-hero .hero-text > p {
    position: relative;
    z-index: -1;
}

.spg-video-hero .hero-text > div.spg-button-hidden-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.spg-video-hero .hero-text > p > a.btn-none,
.spg-hero .hero-text > p > a.btn-none,
.hero-text > div.spg-button-hidden-wrapper > a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
}

.spg-video-hero .hero-text .play {
    position: relative;
}

.spg-video-hero .pause,
.spg-video-hero .play,
.spg-video-hero .hero-text {
    z-index: 2;
}

    .spg-video-hero .pause:focus,
    .spg-video-hero .play:focus {
        fill: var(--gray-lighter);
        opacity: 0.75;
    }

main .navbar {
    border-bottom: 1px solid var(--gray-light);
    z-index: 2;
}

@media(--lg-viewport-up) {
    .spg-ad-spot.spg-ad-spot-tall {
        position: relative;
        width: 100%;
        padding-top: calc(200% + 1.736111rem);
        & picture

{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

}

.spg-ad-spot.spg-ad-spot-wide {
    position: relative;
    width: 100%;
    padding-top: 50%;
    & picture

{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

}

.spg-ad-spot {
    position: relative;
    width: 100%;
    padding-top: 100%;
    margin-bottom: 1.736111rem;
    overflow: hidden;
    display: block;
    & img

{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

& h2 {
    margin-bottom: 1.25rem;
}

    & h2 + p {
        margin-bottom: 1.4375rem;
    }

}

.featured-ad-spot-container .spg-ad-spot {
    margin-bottom: 0;
}

.spg-ad-spot-container {
    margin-bottom: -1.736111rem;
}

.spg-ad-spot-image-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease-out;
}

    .spg-ad-spot-image-container.cta-hover {
        width: 102%;
        height: 102%;
    }

.spg-ad-spot-wide .spg-ad-spot-image-container.cta-hover {
    width: 101%;
    height: 101%;
}
/* Default font sizing for desktop */

.spg-ad-spot p {
    font-size: 1.0625rem;
}

    .spg-ad-spot p a,
    .spg-ad-spot-link {
        font-size: 0.92361111111rem;
    }

}

@media(--lg-viewport-down) {
    .spg-ad-spot img {
        display: block;
        width: 100%;
    }

    .spg-ad-spot .hero-text {
        padding: 0.75rem 0 2rem 0;
    }

        .spg-ad-spot .hero-text p a {
            transition: all 0.2s ease-out;
        }

    .spg-ad-spot.spg-hero .hero-text p a:hover,
    .spg-ad-spot.spg-hero .hero-text p a:focus {
        color: var(--brand-primary);
    }

    .spg-ad-spot.spg-hero h2 {
        font-family: 'SimonCircularPro-Bold', sans-serif;
        font-size: 1.225rem;
        margin-bottom: 1rem;
    }

        .spg-ad-spot.spg-hero h2 + p {
            margin-bottom: 0.75rem;
        }

    .spg-ad-spot .hero-text > .text-center,
    .spg-ad-spot .hero-text > .text-left,
    .spg-ad-spot .hero-text > .text-right {
        text-align: left;
    }

    .featured-ad-spot-container .spg-ad-spot {
        padding: 0 .8680555rem;
    }

        .featured-ad-spot-container .spg-ad-spot img,
        .discover-slider .slick-track > div:only-child .spg-ad-spot img {
            width: 100%;
        }

    .discover-slider .slick-track > div:only-child {
        float: none;
    }

    .discover-slider p a {
        font-size: 0.8rem;
    }

    .discover-slider > div:nth-child(2) > .col-xs-6 {
        padding-right: 0.225rem;
    }

    .discover-slider > div:nth-child(3) > .col-xs-6 {
        padding-left: 0.225rem;
    }

    .spg-ad-spot .hero-top-left, .spg-ad-spot .hero-top-center, .spg-ad-spot .hero-top-right,
    .spg-ad-spot .hero-middle-left, .spg-ad-spot .hero-middle-center, .spg-ad-spot .hero-middle-right,
    .spg-ad-spot .hero-bottom-left, .spg-ad-spot .hero-bottom-center, .spg-ad-spot .hero-bottom-right {
        position: relative;
        left: 0px;
        top: 0px;
        bottom: initial;
        right: initial;
        transform: translate(0%, 0);
        text-align: left;
        background: none;
    }

    .spg-ad-spot.spg-hero h2,
    .spg-ad-spot.spg-hero p,
    .spg-ad-spot.spg-hero p a:not(.btn),
    .spg-ad-spot.spg-hero p span {
        color: black !important;
    }

    .spg-ad-spot .spg-hero h2 {
        font-family: SimonCircularPro-Light, sans-serif;
    }

    /* Set Default Font Styles for Mobile */
    .fonts-loaded {
        .spg-ad-spot.spg-hero h2, .spg-ad-spot.spg-hero h2 a, .spg-ad-spot.spg-hero h2 span

{
    font-family: "SimonCircularPro-Bold", sans-serif !important;
    color: #000 !important;
    text-align: left;
}

.spg-ad-spot.spg-hero p {
    font-family: "SimonCircularPro-Book", sans-serif !important;
    color: #000 !important;
    text-align: left;
}

}

.spg-ad-spot .hero-text br,
.spg-ad-spot .hero-text br:after {
    content: ' ';
}

    .spg-ad-spot-image-container {
        position: relative;
    }

}

@media(--md-viewport-up) {

    .redesign-logo-slider .logo img {
        padding: 5%;
    }

    .redesign-logo-slider .logo a:hover img {
        position: relative;
        max-width: none;
        padding: 8%;
        background-color: var(--gray-light);
    }

    .main-hero img,
    .main-hero video {
        object-fit: cover;
        object-position: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .main-hero {
        height: calc(100vh - (var(--navbar-height) * 2)); /* Calculating for header & nav bar */
        width: 100%;
        overflow: hidden;
        position: relative;
    }

        .main-hero .hero-text > h2,
        .main-hero .hero-text > p,
        .main-hero .hero-text > a {
            margin-bottom: 1.25rem;
        }

            .main-hero .hero-text > h2 + p {
                margin-bottom: 1.475rem;
            }

    .video-inner-container .play {
        display: none;
    }

    .national-hero .main-hero {
        height: calc(100vh - var(--navbar-height)); /* Calculating for header */
    }

    .hero-container .col-md-12 {
        padding: 0;
    }

    .explore-slider img {
        margin-bottom: 1rem;
    }

    .main-hero.ms-video-workaround video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
    }

    /* Default font sizing for desktop */

    .description-headline p,
    .description-headline a,
    .spg-ad-spot p,
    .explore-slider p,
    .service-spot p,
    .redesign-spo-carousel h3 {
        font-size: 1.1475rem;
    }
    .description-headline a {
        font-weight: bold;
    }

    .spg-ad-spot p a,
    .explore-slider div > a,
    .spg-ad-spot-link {
        font-size: 0.9975rem;
    }
}

.explore-slider h5 {
    font-size: 1.725rem;
    margin-bottom: 0.6414rem;
}
@media(--md-viewport-up) {
    .explore-slider .col-xs-6.col-md-12:first-child {
        position: relative;
        width: calc(100% - 1.73612rem);
        padding-top: calc(100% - 1.73612rem);
        margin-left: 0.86806rem;
        margin-bottom: 1rem;
        overflow: hidden;

        & > .promo-image-wrapper {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            transition: all 0.5s;

            &:hover, &:focus {
                width: 103%;
                height: 103%;
            }

            & > img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

@media(--md-viewport-down) {
    .main-hero .spg-button:hover,
    .main-hero .spg-button:focus {
        color: var(--brand-primary);
    }

    .explore-slider h5 {
        font-size: 1.225rem;
        margin-bottom: 1rem;
        font-family: 'SimonCircularPro-Bold', sans-serif;
    }

    .explore-slider .col-sm-12 {
        margin-bottom: 1rem;
    }

    .explore-slider > .HtmlContentClass:last-child .col-sm-12 {
        margin-bottom: 0;
    }

    .explore-slider div > a {
        font-size: 0.8rem;
    }

    .hero-top-left, .hero-top-center, .hero-top-right,
    .hero-middle-left, .hero-middle-center, .hero-middle-right,
    .hero-bottom-left, .hero-bottom-center, .hero-bottom-right,
    .spg-video-hero .overlay {
        position: relative;
        left: 0px;
        top: 0px;
        bottom: initial;
        right: initial;
        transform: translate(0%, 0);
        text-align: left;
        background: none;
    }

    .main-hero div {
        text-align: center;
    }

    .spg-hero h1, .spg-hero h2, .spg-hero p, .spg-hero p a:not(.btn) {
        width: 100%;
        color: black;
    }

    .spg-hero h1, .spg-hero h2 {
        font-family: SimonCircularPro-Light, sans-serif;
    }

    .hero-text {
        padding: 3rem 0;
    }

    .fonts-loaded .main-hero .hero-text p,
    .main-hero .hero-text .text-left,
    .main-hero .hero-text .text-center,
    .main-hero .hero-text .text-right,
    .fonts-loaded .main-hero.spg-video-hero .hero-text p {
        text-align: center;
        color: #000;
    }

    .main-hero .spg-button {
        color: #000;
        background-color: #fff;
        border: none;
        text-decoration: underline;
        padding: 0;
    }

    .main-hero a.spg-button:hover,
    .main-hero a.spg-button:focus {
        background-color: transparent;
    }


    .main-hero {
        height: auto;
    }

    .video-inner-container {
        position: relative;
    }

    .spg-video-hero .pause {
        z-index: 2;
    }

    .overlay .play {
        display: none;
    }

    video.v2-video,
    .spg-video-hero .video-fallback {
        height: auto;
    }

    /* Set Default Font Styles for Mobile */
    .fonts-loaded {
        .hero-text .header-xl, .hero-text .display-5, .spg-video-hero .hero-text h1, .explore-header h2, .services-header h2

    {
        font-family: "SimonCircularPro-Light", sans-serif !important;
        color: #000;
        text-align: center;
    }

.hero-text svg.icon-play {
    fill: #000;
}

.explore-slider h5,
.spg-ad-spot.spg-hero h2,
.spg-ad-spot.spg-hero h2 a,
.spg-ad-spot.spg-hero h2 span
{
    /*font-family: "SimonCircularPro-Bold", sans-serif !important;*/
    color: #000;
    text-align: left;
}

.explore-slider p,
.spg-ad-spot.spg-hero p,
.spg-video-hero .hero-text p {
    font-family: "SimonCircularPro-Book", sans-serif !important;
    color: #000;
    text-align: left;
}
.description-headline h3, .description-headline p {
    text-align: center;
}

}

.main-hero p {
    font-size: 0.92361111111rem;
}

.spg-ad-spot.spg-hero h2 {
    /*font-size: 0.92361rem;*/
    font-size: 1.25rem;
    margin-bottom: 0.6414rem;
}

.fonts-loaded .spg-hero .hero-text,
.fonts-loaded .spg-video-hero .hero-text,
.fonts-loaded .main-hero.spg-video-hero .hero-text {
    & .text-brand-primary-mobile

{
    color: var(--brand-primary);
}

& .text-brand-secondary-mobile {
    color: var(--brand-secondary);
}

& .text-brand-primary-forum-mobile {
    color: var(--brand-primary-forum);
}

& .text-brand-primary-mills-mobile {
    color: var(--brand-primary-mills);
}

& .text-brand-gray-mobile {
    color: var(--brand-gray);
}

& .text-gray-mobile {
    color: var(--gray);
}

& .text-black-mobile {
    color: #000;
}

& .text-white-mobile {
    color: #fff;
}

& .text-holiday-mobile {
    color: var(--holiday-red);
}

& .text-brand-turquoise-mobile {
    color: var(--brand-turquoise);
}

& .medium-mobile {
    font-family: "SimonCircularPro-Medium", sans-serif !important;
}

& .bold-mobile {
    font-family: "SimonCircularPro-Bold", sans-serif !important;
}

& .black-mobile {
    font-family: "SimonCircularPro-Black", sans-serif !important;
}

& .book-mobile {
    font-family: "SimonCircularPro-Book", sans-serif !important;
}

& .light-mobile,
& .thin-mobile {
    font-family: "SimonCircularPro-Light", sans-serif !important;
}

& .italic-mobile {
    font-family: "SimonCircularPro-Bookitalic", sans-serif !important;
}

& .bold-italic-mobile {
    font-family: "SimonCircularPro-Bolditalic", sans-serif !important;
}

& .black-italic-mobile {
    font-family: "SimonCircularPro-Blackitalic", sans-serif !important;
}

& .medium-italic-mobile {
    font-family: "SimonCircularPro-Mediumitalic", sans-serif !important;
}

& .thin-italic-mobile,
& .light-italic-mobile {
    font-family: "SimonCircularPro-Lightitalic", sans-serif !important;
}

& .text-left-mobile {
    text-align: left;
}

& .text-center-mobile {
    text-align: center;
}

& .text-right-mobile {
    text-align: right;
}

}
}

@media(--sm-viewport-up) {
    .redesign-logo-slider,
    .explore-slider,
    .redesign-spo-carousel .spo-carousel {
        overflow: visible;
    }

        .redesign-logo-slider .slick-list,
        .explore-slider .slick-list,
        .redesign-spo-carousel .spo-carousel .slick-list {
            overflow: hidden;
        }

    .redesign-spo-carousel h3.copy {
        font-size: 1.15rem;
    }
}

#centerDescription .header-xl,
div[name="business-promoheadline"] .header-xl,
.leasing-mappedin-wrapper .header-xl {
    font-size: calc(1.375rem + 1.5vw);

    @media (min-width: 1200px) {
        font-size: 2.5rem;
    }
}

@media (min-width: 1600px) {
    .viewport-xxl-containers .container {
        max-width: 1500px;
    }
}

.spg-ad-spot .header-md {
    font-size: 1.725rem;
}

.spg-ad-spot a,
.spg-ad-spot p {
    line-height: 1.25;
}

#flippingBookIframe {
    height: 575px;
    max-height: calc(100vh - 100px);
    min-height: 300px;

    @media(--md-viewport-down) {
        max-height: calc(100vh - 200px);
        min-height: 200px;
    }

    @media(--sm-viewport-down) {
        height: 350px;
        min-height: 200px;
    }
}

.leasing-mappedin-wrapper #mappedin-map {
    position: relative;
    width: 100%;
    height: 600px;
    min-height: 300px;
    max-height: calc(100vh - 74px);
}

.aerial-view-button {
    position: absolute;
    bottom: 2rem;
    right: 1rem;
    z-index: 1000;
    background: none;
    border: none;
    padding: 0;
    border-radius: 50%;
    background-color: var(--white);

    & svg {
        clip-path: circle(40%);
    }

    &:focus svg,
    &:hover svg {
        fill: var(--gray-darker);
    }
}
/* Color Utilities */

.text-brand-primary {
  color: var(--brand-primary);
}

.text-brand-secondary {
  color: var(--brand-secondary);
}

.text-brand-primary-forum {
  color: var(--brand-primary-forum);
}

.text-brand-primary-mills {
  color: var(--brand-primary-mills);
}

.text-brand-turquoise {
  color: var(--brand-turquoise);
}

.text-brand-gray {
  color: var(--brand-gray);
}

.text-gray {
  color: var(--gray);
}

.text-gray-dark {
  color: var(--gray-dark);
}

.text-black {
  color: var(--black);
}

.text-white {
  color: var(--white);
}

.text-brand-black {
    color: var(--black);
}

.text-brand-white {
    color: var(--white);
}

.text-holiday {
  color: var(--holiday-red);
}

.text-family {
  &-aqua {
    color: var(--family-aqua);
  }

  &-red {
    color: var(--family-red2);
  }

  &-blue {
    color: var(--family-blue);
  }

  &-pink {
    color: var(--family-pink);
  }

  &-yellow-green {
    color: var(--family-yellow-green);
  }

  &-purple {
    color: var(--family-purple2);
  }
}

.bg-family {
    &-aqua
    {
        background-color: var(--family-aqua);
    }

    &-red {
    background-color: var(--family-red2);
}

    &-blue {
    background-color: var(--family-blue);
}

    &-pink {
        background-color: var(--family-pink);
    }

    &-yellow-green {
        background-color: var(--family-yellow-green);
    }

    &-purple {
        background-color: var(--family-purple2);
    }
}

.bg-business-red {
    background-color: var(--business-red);
}

.bg-brand-primary {
    background-color: var(--brand-primary);
}

.bg-brand-secondary {
    background-color: var(--brand-secondary);
}